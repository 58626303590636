import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Routes, useNavigate } from 'react-router-dom';
import { onModalOpen, onChangeDarkMode, onChangeHighcontrast } from '../Redux/Actions/Actions';
import Header from '../Component/Common/Header';
import SettingsModal from '../Component/Common/SettingsModal';
import Home from './Home';
import Profile from './UserProfile';
import ErrorPage from "../Component/Auth/ErrorPage";
import FlaresoftLogo from "../assets/images/flaresoft_base_logo_transparent_background.png"; // Assuming you already have the logo in assets

function MainIndex(props) {
    const { modalopen, darkMode, highcontrast } = props.Mainreducer;
    const { activekey, GotoChangeMenu } = props;
    const navigate = useNavigate();
    const baseUrl = process.env.PUBLIC_URL;

    return (
        <div className='main px-lg-4 px-md-4'>
            <Header
                onModalOpen={(val) => { props.onModalOpen(true) }}
                GotoChangeMenu={(val) => { GotoChangeMenu(val) }}
            />
            <SettingsModal
                show={modalopen}
                highcontrast={highcontrast}
                darkMode={darkMode}
                onHide={(val) => { props.onModalOpen(false) }}
                onChangeDarkMode={() => { props.onChangeDarkMode(darkMode === 'dark' ? 'light' : 'dark') }}
                onChangeHighcontrast={() => { props.onChangeHighcontrast(highcontrast === 'high-contrast' ? 'light' : 'high-contrast') }}
            />
            <div className="body d-flex py-3 ">
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/home' element={<Home />} />
                    <Route path='/profile/*' element={<Profile />} />
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
            </div>

            {/* Footer */}
            <footer className="footer">
                <div className="container text-center">
                    <hr className="custom-separator" />
                    <p>Powered by Flaresoft Solutions Inc.</p>
                    <a href="https://www.flaresoft.solutions" target="_blank" rel="noopener noreferrer">
                        <img className="flaresoft-logo" src={FlaresoftLogo} alt="Flaresoft Solutions" />
                    </a>
                </div>
            </footer>
        </div>
    )
}

const mapStateToProps = ({ Mainreducer }) => ({
    Mainreducer
})

export default connect(mapStateToProps, {
    onModalOpen,
    onChangeDarkMode,
    onChangeHighcontrast
})(MainIndex);