import React, {useState} from 'react';

const ConfirmSignInNewPassword = ({onSubmit, loading, setLoading, setError}) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        setError('');
        setLoading(true);

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }

        onSubmit(newPassword);
    };

    return (
        <>
            <div className="alert alert-info" role="alert">
                Please set a new password to continue the login process. Your new password must be different from your
                previous passwords.
            </div>
            <form onSubmit={handleSubmit}>
                <div className="mb-3 mt-5">
                    <label className="form-label fs-6">New Password</label>
                    <input type="password" className="form-control" value={newPassword}
                           onChange={e => setNewPassword(e.target.value)} required/>
                </div>
                <div className="mb-3">
                    <label className="form-label fs-6">Confirm Password</label>
                    <input type="password" className="form-control" value={confirmPassword}
                           onChange={e => setConfirmPassword(e.target.value)} required/>
                </div>
                <button type="submit" disabled={loading}
                        className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">
                    {loading ? (
                        <>
                            <span className="spinner-border spinner-border-sm me-2" role="status"
                                  aria-hidden="true"></span>
                            Loading...
                        </>
                    ) : (
                        "Submit"
                    )}
                </button>
            </form>
        </>
    );
};

export default ConfirmSignInNewPassword;
