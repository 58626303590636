import React, {useState, useEffect} from "react";
import {signIn, confirmSignIn, fetchAuthSession} from 'aws-amplify/auth';
import { useAuth } from '../../Contexts/AuthContext';
import {useNavigate, Link} from "react-router-dom";
import ConfirmSignInNewPassword from './ConfirmSignInNewPassword';
import ConfirmSignInTOTP from './ConfirmSignInTOTP';
import ConfirmSignInSMS from './ConfirmSignInSMS';
import ConfirmSignInSetupTOTP from './ConfirmSignInSetupTOTP';

function Login() {
    const navigate = useNavigate();
    const { isAuthenticated, authenticatedEmail, checkAuthState, setIsAuthenticated } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(null);
    const [nextStepDetails, setNextStepDetails] = useState(null);
    const [authCheckComplete, setAuthCheckComplete] = useState(false);

    useEffect(() => {
        checkAuthState().finally(() => setAuthCheckComplete(true));
    }, [checkAuthState]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setLoading(true);

        try {
            const {isSignedIn, nextStep} = await signIn({username: email, password});
            await handleNextStep(nextStep);
        } catch (error) {
            setError(error.message || "An error occurred during login.");
        } finally {
            setLoading(false);
        }
    };

    const handleNextStep = async (nextStep) => {
        setNextStepDetails(nextStep);

        switch (nextStep.signInStep) {
            case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
                setCurrentStep('NEW_PASSWORD_REQUIRED');
                break;
            case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
                setCurrentStep('SETUP_TOTP');
                break;
            case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
                setCurrentStep('TOTP');
                break;
            case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
                setCurrentStep('SMS_CODE');
                break;
            case 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION':
                // For MFA selection, user must select either SMS or TOTP
                const mfaType = 'TOTP';
                const {nextStep: mfaStep} = await confirmSignIn({challengeResponse: mfaType});
                await handleNextStep(mfaStep);
                break;
            case 'RESET_PASSWORD':
                setError('Your password has expired. Please use the "Password Reset" link to reset your password.');
                setCurrentStep('NONE');
                break;
            case 'CONFIRM_SIGN_UP':
                setError('Please confirm your email before logging in.');
                setCurrentStep('NONE');
                break;
            case 'DONE':
                setCurrentStep('NONE');
                console.log("Login successful");
                navigate('/dashboard');
                break;
            default:
                setError('Unhandled step: ' + nextStep.signInStep);
                setCurrentStep('NONE');
        }
    };

    const handleConfirmSignIn = async (challengeResponse, options) => {
        setError('');
        setLoading(true);

        try {
            const {isSignedIn, nextStep} = await confirmSignIn({challengeResponse});
            if (isSignedIn) {
                console.log("Login successful");
                await storeToken(); // Store the token after successful login
                navigate('/dashboard');
            } else {
                await handleNextStep(nextStep);
            }
        } catch (error) {
            setError(error.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const storeToken = async () => {
        try {
            const session = await fetchAuthSession();
            const idToken = session.tokens.idToken;
            setIsAuthenticated(true);
            if (idToken) {
                localStorage.setItem('idToken', idToken);
            }
        } catch (error) {
            console.error('Error getting current session:', error);
        }
    };

    return (
        <>
            <div className="body d-flex p-0 p-xl-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="d-flex flex-column align-items-start">
                                <h1 className="mb-4">Welcome Back to SCADASwarm!</h1>
                                <h5>Sign In to Continue</h5>
                                <span className="text-muted">Access your cloud-based SCADA dashboard to manage and optimize your operations. Let's drive innovation together.</span>
                                <div className="card mt-4 w-100">
                                    <div className="card-body p-4">
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        {authCheckComplete ? (
                                            isAuthenticated ? (
                                                <div>
                                                    <p>You are already signed in as {authenticatedEmail}. Click below to
                                                        continue to your dashboard.</p>
                                                    <button onClick={() => navigate('/dashboard')}
                                                            className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">
                                                        Continue
                                                    </button>
                                                </div>
                                            ) : (
                                                <>
                                                    {currentStep === 'NEW_PASSWORD_REQUIRED' && (
                                                        <ConfirmSignInNewPassword
                                                            onSubmit={(newPassword) => handleConfirmSignIn(newPassword, {userAttributes: {password: newPassword}})}
                                                            loading={loading}
                                                            setLoading={setLoading}
                                                            setError={setError}
                                                        />
                                                    )}
                                                    {currentStep === 'SETUP_TOTP' && (
                                                        <ConfirmSignInSetupTOTP
                                                            onSubmit={(response) => handleConfirmSignIn(response)}
                                                            nextStep={nextStepDetails}
                                                            email={email}
                                                            loading={loading}
                                                            setLoading={setLoading}
                                                            setError={setError}
                                                        />
                                                    )}
                                                    {currentStep === 'TOTP' && (
                                                        <ConfirmSignInTOTP
                                                            onSubmit={(response) => handleConfirmSignIn(response)}
                                                            loading={loading}
                                                            setLoading={setLoading}
                                                            setError={setError}
                                                        />
                                                    )}
                                                    {currentStep === 'SMS_CODE' && (
                                                        <ConfirmSignInSMS
                                                            onSubmit={(response) => handleConfirmSignIn(response)}
                                                            loading={loading}
                                                            setLoading={setLoading}
                                                            setError={setError}
                                                        />
                                                    )}
                                                    {!currentStep && (
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="mb-3">
                                                                <label className="form-label fs-6">Email address</label>
                                                                <input type="email" className="form-control"
                                                                       value={email}
                                                                       onChange={e => setEmail(e.target.value)}
                                                                       required/>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="form-label fs-6">Password</label>
                                                                <input type="password" className="form-control"
                                                                       value={password}
                                                                       onChange={e => setPassword(e.target.value)}
                                                                       required/>
                                                            </div>
                                                            <button type="submit"
                                                                    className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2"
                                                                    disabled={loading}>
                                                                {loading ? "Loading..." : "Log In"}
                                                            </button>
                                                        </form>
                                                    )}
                                                    <Link to={process.env.PUBLIC_URL + "/forgot-password"}
                                                          className="d-block mt-3">Password Reset</Link>
                                                    <Link to={process.env.PUBLIC_URL + "/register"}
                                                          className="d-block mt-3">Need an account? Register Now!</Link>
                                                </>
                                            )
                                        ) : (
                                            <div className="d-flex justify-content-center mt-4">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
