import React, { useState, useRef, useEffect } from 'react';

const ConfirmSignInTOTP = ({ onSubmit, loading, setLoading, setError }) => {
    const [inputs, setInputs] = useState(Array(6).fill(""));
    const inputRefs = useRef(inputs.map(() => React.createRef()));

    // Ensure the refs array length matches the inputs length
    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, inputs.length);
    }, [inputs]);

    // Focus on the first input on component mount
    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].current.focus();
        }
    }, []);

    useEffect(() => {
        const allFilled = inputs.every(input => input !== "");
        if (allFilled) {
            handleSubmit();
        }
    }, [inputs]);

    const handleInputChange = (index, event) => {
        const newInputs = [...inputs];
        newInputs[index] = event.target.value.slice(0, 1);
        setInputs(newInputs);

        if (event.target.value && index < inputs.length - 1) {
            inputRefs.current[index + 1].current.focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && inputs[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].current.focus();
            }
        }
    };

    const handlePaste = (event) => {
        const paste = event.clipboardData.getData('text').slice(0, 6);
        const newInputs = paste.split('');
        setInputs(newInputs);

        const nextInputIndex = newInputs.length < inputs.length ? newInputs.length : inputs.length - 1;
        if (inputRefs.current[nextInputIndex]) {
            inputRefs.current[nextInputIndex].current.focus();
        }

        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        if (event) event.preventDefault();

        setError('');
        setLoading(true);

        const totpCode = inputs.join('');

        onSubmit(totpCode);
    };

    return (
        <>
            <div className="alert alert-info" role="alert">
                Please enter the Time-based One-Time Password (TOTP) generated by your authenticator app to continue the login process.
            </div>
            <form onSubmit={handleSubmit}>
                <h5 className="mt-4 mb-2">TOTP Code</h5>
                <div className="d-flex justify-content-between mb-4">
                    {inputs.map((value, index) => (
                        <input
                            key={index}
                            ref={inputRefs.current[index]}
                            type="text"
                            className="form-control form-control-lg text-center"
                            style={{ width: "12%" }}
                            maxLength="1"
                            value={value}
                            onChange={(e) => handleInputChange(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            onPaste={handlePaste}
                            required
                        />
                    ))}
                </div>
                <button type="submit" disabled={loading}
                        className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">
                    {loading ? (
                        <>
                            <span className="spinner-border spinner-border-sm me-2" role="status"
                                  aria-hidden="true"></span>
                            Loading...
                        </>
                    ) : (
                        "Submit"
                    )}
                </button>
            </form>
        </>
    );
};

export default ConfirmSignInTOTP;
