import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import logoImage from '../assets/images/base_textlogo_transparent_background.png';
import heroImage from '../assets/images/gallery/engineer-at-work.jpg';
import featureImage1 from '../assets/images/gallery/work-orders-stock-image.jpeg';
import featureImage2 from '../assets/images/gallery/water-flow-pipe.JPG';
import featureImage3 from '../assets/images/gallery/desert-oil-production.jpg';
import featureImage4 from '../assets/images/gallery/chemical-storage-tank.JPG';
import featureImage5 from '../assets/images/gallery/well-drilling-rig.jpg';
import featureImage6 from '../assets/images/gallery/asset-management.jpg';

function Home() {
    return (
        <Container fluid className="px-0">
            {/* Logo and Welcome Section */}
            <Row noGutters className="align-items-center">
                <Col className="text-center py-5">
                    <img src={logoImage} alt="Field Warden Logo" className="home-logo img-fluid" />
                    <h3 className="mt-4 mb-2 text-break display-6 fw-bold"
                        style={{ letterSpacing: '1px', textShadow: '2px 2px 8px rgba(0,0,0,0.6)' }}>
                        Oil & Gas Operations Management Platform
                    </h3>
                </Col>
            </Row>

            {/* Hero Image Section */}
            <Row noGutters className="align-items-center">
                <Col>
                    <img src={heroImage} alt="Hero Background" className="img-fluid" style={{
                        width: '100%',
                        height: '45vh',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        borderRadius: '20px'
                    }} />
                </Col>
            </Row>

            {/* Mission and Vision Section */}
            <Row className="my-5 justify-content-center">
                <Col md={8}>
                    <Card className="text-center p-4">
                        <Card.Body>
                            <Card.Title>Our Mission</Card.Title>
                            <Card.Text>To provide an all-encompassing platform that simplifies oil & gas operations with innovative tools that enhance safety, productivity, and compliance.</Card.Text>
                            <hr className="mx-0" style={{ width: '100%', height: '2px' }} />
                            <Card.Title>Our Vision</Card.Title>
                            <Card.Text>To empower oil & gas operators with cutting-edge technology, fostering seamless operations and unmatched efficiency in every field.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Key Tools and Features Section */}
            <Row className="my-5 text-center">
                <Col md={4} className="d-flex align-items-stretch">
                    <Card className="w-100">
                        <Card.Img variant="top" src={featureImage1} className="card-img-top" alt="Work Orders" />
                        <Card.Body>
                            <Card.Title>Work Orders</Card.Title>
                            <Card.Text>Create, track, and manage field operation tasks with ease using our intuitive work order system.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} className="d-flex align-items-stretch">
                    <Card className="w-100">
                        <Card.Img variant="top" src={featureImage2} className="card-img-top" alt="Leak Detection" />
                        <Card.Body>
                            <Card.Title>Leak Detection</Card.Title>
                            <Card.Text>Monitor for leaks with real-time alerting to ensure environmental safety and compliance.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} className="d-flex align-items-stretch">
                    <Card className="w-100">
                        <Card.Img variant="top" src={featureImage3} className="card-img-top" alt="Well Monitoring" />
                        <Card.Body>
                            <Card.Title>Well Monitoring</Card.Title>
                            <Card.Text>Continuously monitor well performance, track production, and get detailed analysis and reporting.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Additional Tools Section */}
            <Row className="my-5 text-center">
                <Col md={4} className="d-flex align-items-stretch">
                    <Card className="w-100">
                        <Card.Img variant="top" src={featureImage4} className="card-img-top" alt="Chemical Management" />
                        <Card.Body>
                            <Card.Title>Chemical Management</Card.Title>
                            <Card.Text>Keep track of chemical usage and ensure all regulatory compliance requirements are met with our integrated chemical management tool.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} className="d-flex align-items-stretch">
                    <Card className="w-100">
                        <Card.Img variant="top" src={featureImage5} className="card-img-top" alt="Well Servicing" />
                        <Card.Body>
                            <Card.Title>Well Servicing</Card.Title>
                            <Card.Text>Manage well servicing tasks with precision, ensuring all maintenance and servicing activities are tracked and executed efficiently.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} className="d-flex align-items-stretch">
                    <Card className="w-100">
                        <Card.Img variant="top" src={featureImage6} className="card-img-top" alt="Asset Management" />
                        <Card.Body>
                            <Card.Title>Asset Management</Card.Title>
                            <Card.Text>Efficiently manage all your assets, from facilities to equipment, with detailed tracking, maintenance schedules, and lifecycle management. Stay informed with real-time updates and ensure operational efficiency across your entire asset portfolio.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Call to Action Section */}
            <Row className="text-center mb-5">
                <Col>
                    <Button variant="primary" size="lg">Explore Our Platform</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;
